<template>
  <div>
    <easy-card title="停车业务">
      <!-- <div class="isStartUsing">
        <el-button>已启用</el-button>
      </div> -->
      <div class="priceButton">
        <el-button type="primary" @click="simulateDialogFormVisible = true"
        >模拟计费
        </el-button
        >
        <el-button type="primary" @click="foundDialogFormVisible = true"
        >创建计费
        </el-button
        >
      </div>
      <el-table
          v-loading="isLoading"
          :data="priceRuleList"
          border
          class="common-table"
          size="medium"
          style="width: 100%"
      >
        <el-table-column label="计费策略">
          <template slot-scope="scope">
            <span>{{
                scope.row.calcType === 0 ? "默认计费" : "特殊计费"
              }}</span>
          </template>
        </el-table-column>
        <el-table-column label="生效时间" width="340">
          <template slot-scope="scope">
            <span>{{ scope.row.activateTime }}</span>
            <span v-if="scope.row.activateStatus === 2"
            >~{{ scope.row.activateEnd }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="计费描述">
          <template slot-scope="scope">
            <span>{{ scope.row.remark }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间">
          <template slot-scope="scope">
            <span>{{ scope.row.createTime }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="创建人"> </el-table-column> -->
        <el-table-column label="状态">
          <template slot-scope="scope">
            <span>{{
                scope.row.activateStatus === 0
                    ? "待生效"
                    : scope.row.activateStatus === 1
                        ? "已生效"
                        : "已失效"
              }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="340">
          <template slot-scope="scope">
            <el-button
                class="common-text"
                size="small"
                type="text"
                @click="
                (detailDialogFormVisible = true),
                  goPriceRuleDetail(scope.row.id)
              "
            >查看
            </el-button
            >
            <el-button
                size="small"
                style="font-size: 14px"
                type="text"
                @click="deletePriceRule(scope.row.id)"
            >删除
            </el-button
            >
            <el-button
                class="common-text"
                size="small"
                type="text"
                @click="goEditPriceRule(scope.row)"
            >编辑
            </el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </easy-card>

    <div class="pagination-box">
      <el-pagination
          :current-page="1"
          :page-size="15"
          :page-sizes="[10, 15, 30, 50]"
          :total="total"
          background
          layout="sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>

    <!-- 模拟计费弹出框 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="simulateDialogFormVisible"
        class="common-dialog"
        title="模拟计费"
    >
      <el-form ref="simulatePriceRule" :model="simulatePriceRule">
        <el-form-item :label-width="formLabelWidth" label="当前车场">
          {{ parkLotName }}
        </el-form-item>
        <el-form-item
            :label-width="formLabelWidth"
            label="选择车牌"
            prop="plateType"
        >
          <el-select v-model="simulatePriceRule.plateType">
            <el-option label="全部车牌" value="0"></el-option>
            <el-option label="黄牌" value="01"></el-option>
            <el-option label="蓝牌" value="02"></el-option>
            <el-option label="黑牌" value="06"></el-option>
            <el-option label="新能源" value="03,04"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
            :label-width="formLabelWidth"
            label="驶入驶出时间"
            prop="effectualTime"
        >
          <el-date-picker
              v-model="simulatePriceRule.time"
              end-placeholder="驶出时间"
              range-separator="~"
              start-placeholder="驶入时间"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <el-table
          :data="simulatePriceTable"
          border
          class="common-table"
          size="medium"
          style="width: 100%"
      >
        <el-table-column label="计费策略" prop="priceRuleName">
          <!-- <template>
            <span>默认计费</span>
            </template>  -->
        </el-table-column>
        <el-table-column label="计费金额" prop="amount"></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="simulatePrice">计费</el-button>
        <el-button @click="closeDialog">关闭</el-button>
      </div>
    </el-dialog>

    <!-- 创建计费弹出框 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="foundDialogFormVisible"
        class="common-dialog"
        title="创建计费"
    >
      <el-form ref="foundPriceRule" :model="foundPriceRule">
        <el-form-item
            :label-width="formLabelWidth"
            label="计费策略"
            prop="leaseTitle"
        >
          <el-row>
            <el-col :span="6">
              <el-select v-model="foundPriceRule.calcType" class="select-inner">
                <el-option label="默认计费" value="0"></el-option>
                <!-- <el-option label="特殊计费" value="1"></el-option> -->
              </el-select>
            </el-col>
            <!-- <el-col :span="6">
              <el-input
                v-model="foundPriceRule.specialPriceName"
                placeholder="请输入特殊计费名称"
              ></el-input>
            </el-col> -->
          </el-row>
        </el-form-item>

        <el-form-item
            :label-width="formLabelWidth"
            label="生效时间"
            prop="leaseTitle"
        >
          <el-row>
            <el-col :span="6">
              <el-select
                  v-model="foundPriceRule.takeEffect"
                  class="select-inner"
                  @change="slelctTakeEffect"
              >
                <el-option label="现在" value="0"></el-option>
                <el-option label="定时生效" value="1"></el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <span v-if="showSelectTime === true">
                <el-date-picker
                    v-model="foundPriceRule.activateTime"
                    placeholder="选择日期时间"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </span>
            </el-col>
          </el-row>
        </el-form-item>

        <el-form-item
            :label-width="formLabelWidth"
            label="计费描述"
            prop="leaseTitle"
        >
          <el-input
              v-model="foundPriceRule.remark"
              :rows="2"
              placeholder="输入计费描述"
              type="textarea"
          >
          </el-input>
        </el-form-item>
        <!-- <div>* 默认计费为车场通用计费规则，特殊计费仅用于分组计费。</div> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="colseFoundDialog">取 消</el-button>
        <!-- <el-button type="primary">复制计费</el-button> -->
        <el-button type="primary" @click="goFoundPriceRule">创建计费</el-button>
      </div>
    </el-dialog>

    <!-- 编辑计费弹出窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="editDialogFormVisible"
        class="common-dialog"
        title="编辑计费"
    >
      <el-form ref="foundPriceRule" :model="currentRow">
        <el-form-item label="计费策略" prop="leaseTitle">
          <el-row>
            <el-col :span="6">
              <el-select
                  v-model="currentRow.calcType"
                  class="select-inner"
                  disabled
              >
                <el-option :value="0" label="默认计费"></el-option>
                <!-- <el-option label="特殊计费" value="1"></el-option> -->
              </el-select>
            </el-col>
            <!-- <el-col :span="6">
              <el-input
                v-model="foundPriceRule.specialPriceName"
                placeholder="请输入特殊计费名称"
              ></el-input>
            </el-col> -->
          </el-row>
        </el-form-item>

        <el-form-item label="生效时间" prop="leaseTitle">
          <el-row>
            <el-col :span="6">
              <el-select
                  v-model="currentRow.activateType"
                  class="select-inner"
                  @change="selectTakeEffect"
              >
                <el-option :value="0" label="现在"></el-option>
                <el-option :value="1" label="定时生效"></el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <span v-if="showSelectTime === true">
                <el-date-picker
                    v-model="currentRow.activateTime"
                    placeholder="选择日期时间"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </span>
            </el-col>
          </el-row>
        </el-form-item>

        <el-form-item label="计费描述" prop="leaseTitle">
          <el-input
              v-model="currentRow.remark"
              :rows="2"
              placeholder="输入计费描述"
              type="textarea"
          >
          </el-input>
        </el-form-item>
        <!-- <div>* 默认计费为车场通用计费规则，特殊计费仅用于分组计费。</div> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="colseEditDialog">取 消</el-button>
        <el-button type="primary" @click="saveEdit">完成</el-button>
        <el-button type="primary" @click="doEditPriceRule">编辑计费</el-button>
      </div>
    </el-dialog>

    <!-- 查看计费规则详情 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="detailDialogFormVisible"
        class="common-dialog"
        title="查看计费"
    >
      <div>
        <easy-card class="box" title="免费时长">
          <p v-if="freeTimeTable.length > 0" class="remarkTip">
            减免方式：{{
              freeTimeTable[0].reductionType === 0
                  ? "按订单时段减免"
                  : "按计费时段减免"
            }}/{{
              freeTimeTable[0].calcTimeFree === 0 ? "超时计入" : "超时不计入/"
            }}
            <span v-if="freeTimeTable[0].calcTimeFree === 1">{{
                freeTimeTable.freeType === 0 ? "前免费" : "后免费"
              }}</span>
          </p>
          <el-table
              :data="freeTimeTable"
              border
              class="common-table"
              size="medium"
              style="width: 100%"
          >
            <el-table-column label="生效车牌" prop="plateType">
              <template slot-scope="scope">
                <span>{{ formatNumplate(scope.row.plateType) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="生效周期" prop="activePeriod">
              <template slot-scope="scope">
                <span>{{
                    scope.row.activePeriod === 0
                        ? "不限"
                        : scope.row.activePeriod === 1
                            ? "工作日"
                            : "休息日"
                  }}</span>
              </template>
            </el-table-column>
            <el-table-column label="免费时长" prop="freeTime">
              <template slot-scope="scope">
                {{ scope.row.freeTime }}分钟
              </template>
            </el-table-column>
          </el-table>
        </easy-card>
      </div>

      <div>
        <easy-card class="box" title="封顶金额">
          <p v-if="topRuleTable.length > 0" class="remarkTip">
            减免方式：{{
              topRuleTable.limitType === 0
                  ? "按自然日"
                  : topRuleTable.limitType === 1
                      ? "按24小时"
                      : "按整笔订单"
            }}
          </p>
          <el-table
              :data="topRuleTable"
              border
              class="common-table"
              size="medium"
              style="width: 100%"
          >
            <el-table-column label="生效车牌" prop="plateType">
              <template slot-scope="scope">
                <span>{{ formatNumplate(scope.row.plateType) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="生效周期" prop="activePeriod">
              <template slot-scope="scope">
                <span>{{ scope.row.activePeriod === 0 ? "不限" : "" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="时间段" prop="activeType">
              <template slot-scope="scope">
                <span>{{ scope.row.activeType === 0 ? "全天" : "" }}</span>
                <!-- <span v-if="scope.row.activeType === '1'">{{scope.row.activeTimeBegin}}~{{scope.row.activeTimeEnd}}</span> -->
              </template>
            </el-table-column>
            <el-table-column label="封顶金额(元)" prop="maxLimit">
            </el-table-column>
          </el-table>
        </easy-card>
      </div>

      <div>
        <easy-card class="box" title="计费规则">
          <el-table
              :data="priceRuleTable"
              border
              class="common-table"
              size="medium"
              style="width: 100%"
          >
            <el-table-column label="生效车牌" prop="plateType">
              <template slot-scope="scope">
                <span>{{ formatNumplate(scope.row.plateType) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="生效周期" prop="activeTimeType">
              <template slot-scope="scope">
                {{
                  scope.row.activeTimeType === 0
                      ? "不限"
                      : scope.row.activeTimeType === 1
                          ? "工作日"
                          : "休息日"
                }}
                <span v-if="scope.row.activeLegalHolidays === 0"
                >(启用法定节假日)</span
                >
              </template>
            </el-table-column>
            <el-table-column label="时间段" prop="effectualTime">
              <template slot-scope="scope">
                {{
                  scope.row.activeTimeScope === "00:00:00-23:59:59"
                      ? "全天"
                      : ""
                }}
                <span
                    v-if="scope.row.activeTimeScope !== '00:00:00-23:59:59'"
                >{{ scope.row.activeTimeScope }}</span
                >
              </template>
            </el-table-column>
            <el-table-column label="计费方式" prop="contactNumber" width="250">
              <template slot-scope="scope">
                <span v-if="scope.row.ruleType === 0">
                  {{ scope.row.price }}元/{{
                    scope.row.calcUnit
                  }}分钟/不足单位时长{{
                    scope.row.notEnoughUnit === 0 ? "收费" : "免费"
                  }}/切换计费时段{{
                    scope.row.unitChange === 0
                        ? "开始新单位时长"
                        : "继续当前单位时长"
                  }}
                </span>
                <span v-if="scope.row.ruleType === 1">
                  {{ scope.row.price }}元/次<span
                    v-if="scope.row.freeTime !== ''"
                >,按次免费时长{{ scope.row.freeTime }}分钟</span
                >
                </span>
                <span v-if="scope.row.ruleType === 2">
                  {{ formatLadderList(scope.row.calcDetailList) }}
                  <div
                      v-for="item in scope.row.calcDetailList"
                      :key="item.text"
                  >
                    {{ item.text }}
                  </div>
                </span>
              </template>
            </el-table-column>
          </el-table>
        </easy-card>
      </div>

      <div>
        <easy-card class="box" title="预支付时长">
          <el-table
              :data="paymentTimeTable"
              border
              class="common-table"
              size="medium"
              style="width: 100%"
          >
            <el-table-column label="预支付时长(分钟)" prop="prePayTime">
              <template slot-scope="scope">
                <span>{{ scope.row.prePayTime }}</span>
              </template>
            </el-table-column>
          </el-table>
        </easy-card>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeDetail">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {chargeFee, deleteCalcTypeById, getCalcDetailsByCalcTypeId, getCalcTypePage, saveCalcType,} from "@/api/price";
import {getParkingDetail} from "@/api/parking";
import {dateFormat} from "@/utils/utils";

export default {
  name: "share_price_rules",
  props: {
    parkId: String,
  },
  data() {
    return {
      currentRow: {},
      // editId: "",//编辑使用id
      formLabelWidth: "120px",
      isLoading: false,
      // parkId: "",
      parkLotName: "", //车场名称
      pageNo: 1,
      pageSize: 15,
      total: 0,
      foundDialogFormVisible: false, //创建计费弹出窗
      editDialogFormVisible: false, //编辑计费弹出窗
      simulateDialogFormVisible: false, //模拟计费弹出窗
      detailDialogFormVisible: false, //查看计费规则详情弹出窗
      showSelectTime: true, //是否显示时间选择
      priceRuleList: [], //计费规则，停车业务列表
      //创建计费条件表
      foundPriceRule: {
        calcType: "0", //计费策略  0默认计费 (1特殊计费)只有默认计费
        specialPriceName: "", //特殊计费名称
        takeEffect: "1", //生效时间  0现在 1定时生效
        activateTime: "", //定时生效的时间
        remark: "", //计费描述
      },
      //编辑计费条件表
      editPriceRule: {
        calcType: "0", //计费策略  0默认计费 (1特殊计费)只有默认计费
        specialPriceName: "", //特殊计费名称
        takeEffect: "1", //生效时间  0现在 1定时生效
        activateTime: "", //定时生效的时间
        remark: "", //计费描述
      },
      //模拟计费查询条件
      simulatePriceRule: {
        plateType: "", //车牌颜色
        time: "", //驶入驶出时间
      },
      simulatePriceTable: [], //模拟计费表格

      //查看计费规则详情
      freeTimeTable: [], //免费时长表格
      topRuleTable: [], //封顶金额表格
      priceRuleTable: [], //计费规则表格
      paymentTimeTable: [], //预支付时长表格
    };
  },
  watch: {
    parkId(newVal) {
      if (newVal) {
        this.getParkLotDetail();
        this.goSearch();
      }
    },
  },
  methods: {
    doEditPriceRule() {
      this.$router.push({
        path: "/share/parking_manage/share_parking_addprice",
        query: {
          id: this.currentRow.id,
        },
      });
    },
    //处理车牌
    formatNumplate(items) {
      items = typeof items === "string" ? items.split(",") : items;
      return items
          .map((item) => {
            const map = {
              0: "通用",
              "01": "黄牌",
              "02": "蓝牌",
              "06": "黑牌",
              "03": "新能源",
            };
            return map[item];
          })
          .join(",");
    },
    //处理阶梯计费
    formatLadderList(items) {
      items.map((item) => {
        item.text = `${item.calcStart}~${item.calcEnd}分钟, ${item.price}元/${item.calcUnit}分钟`;
        if (item.calcEnd === 99999) {
          item.text = `剩余时长，${item.price}元/${item.calcUnit}分钟`;
        }
      });
    },
    //创建计费
    goFoundPriceRule() {
      const body = {
        parkId: this.parkId,
        calcType: this.foundPriceRule.calcType,
        activateType: this.foundPriceRule.takeEffect,
        activateTime:
            this.foundPriceRule.takeEffect === "0"
                ? dateFormat("yyyy-mm-dd HH:MM:SS", new Date())
                : this.foundPriceRule.activateTime,
        remark: this.foundPriceRule.remark,
      };
      saveCalcType(body).then((res) => {
        if (res && res.code === 30 && res.result) {
          this.foundDialogFormVisible = false;
          this.$router.push({
            path: "/share/parking_manage/share_parking_addprice",
            query: {
              id: res.returnObject.id,
            },
          });
        }
      });
    },
    //编辑计费 保存主表信息
    saveEdit() {
      const body = {
        id: this.currentRow.id,
        parkId: this.parkId,
        calcType: this.currentRow.calcType,
        activateType: this.currentRow.activateType,
        activateTime:
            this.currentRow.activateType === 0
                ? dateFormat("yyyy-mm-dd HH:MM:SS", new Date())
                : this.currentRow.activateTime,
        remark: this.currentRow.remark,
      };
      saveCalcType(body).then((res) => {
        if (res && res.code === 30 && res.result) {
          this.$message.success("保存成功");
          this.editDialogFormVisible = false;
          this.editId = res.returnObject.id;
        }
      });
    },
    // 选择生效时间判断是否显示时间选择器
    slelctTakeEffect() {
      if (this.foundPriceRule.takeEffect === "0") {
        this.showSelectTime = false;
      } else {
        this.showSelectTime = true;
      }
    },
    // 选择生效时间判断是否显示时间选择器
    selectTakeEffect() {
      if (this.currentRow.activateType === 0) {
        this.showSelectTime = false;
      } else {
        this.showSelectTime = true;
      }
    },
    //查询计费规则列表
    goSearch() {
      const parkId = this.$route.query.parkingId || this.parkId;
      if (!parkId) {
        return;
      }

      const pageNo = this.pageNo;
      const pageSize = this.pageSize;

      this.isLoading = true;
      getCalcTypePage(pageNo, parkId, pageSize).then((res) => {
        this.isLoading = false;
        if (res && res.code === 30 && res.result) {
          this.priceRuleList = res.returnObject.list;
        }
      });
    },
    //查询模拟计费
    simulatePrice() {
      const parkingLotId = this.parkId;
      const autoId = "";
      const plateType = this.simulatePriceRule.plateType;
      const inTime = this.simulatePriceRule.time[0];
      const outTime = this.simulatePriceRule.time[1];
      const simulate = true;
      chargeFee(
          parkingLotId,
          autoId,
          plateType,
          inTime,
          outTime,
          simulate
      ).then((res) => {
        if (res && res.code === 30 && res.result) {
          // this.simulatePriceTable = res.returnObject;
          this.simulatePriceTable = [
            {
              priceRuleName: "默认计费",
              amount: res.returnObject,
            },
          ];
        }
      });
    },
    //关闭模拟计费窗口
    closeDialog() {
      this.simulateDialogFormVisible = false;
    },
    //关闭新建计费弹窗
    colseFoundDialog() {
      this.foundDialogFormVisible = false;
    },
    //关闭编辑计费弹窗
    colseEditDialog() {
      this.editDialogFormVisible = false;
    },
    //关闭查看计费窗口
    closeDetail() {
      this.detailDialogFormVisible = false;
    },
    //查询计费规则详情
    goPriceRuleDetail(item) {
      getCalcDetailsByCalcTypeId(item).then((res) => {
        if (res && res.code === 30 && res.result) {
          this.freeTimeTable = res.returnObject.calcFreeList;
          this.topRuleTable = res.returnObject.calcMaxLimitList;
          this.priceRuleTable = res.returnObject.calcList;
          this.paymentTimeTable.push(res.returnObject.calcPrepay);
        }
      });
    },
    //删除计费规则
    deletePriceRule(item) {
      this.$confirm("确认要删除此计费规则吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            deleteCalcTypeById(item).then((res) => {
              if (res && res.code === 30 && res.result) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.goSearch();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    //根据parkId获取停车场详情
    getParkLotDetail() {
      const ParkingLotId = this.$route.query.parkingId || this.parkId;
      if (!ParkingLotId) {
        return;
      }
      getParkingDetail(ParkingLotId).then((res) => {
        if (res && res.code === 30 && res.result) {
          this.parkLotName = res.returnObject.name;
        }
      });
    },
    //编辑计费规则
    goEditPriceRule(item) {
      this.currentRow = item;
      this.editDialogFormVisible = true;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
  mounted() {
    // const parkId = this.$route.query.parkingId;
    // if (parkId) {
    //   this.getParkLotDetail();
    //   this.goSearch();
    // }
    this.getParkLotDetail();
    this.goSearch();
  },
};
</script>

<style lang="less" scoped>

.priceButton {
  text-align: right;
  margin: -10px 0 20px 0;
}

.remarkTip {
  font-size: 14px;
  margin-bottom: 14px;
}
</style>
