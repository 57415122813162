<template>
  <div v-loading="isLoading" class="add-parking-basic">
    <el-form
        ref="form"
        :model="model"
        :rules="rules"
        label-width="200px"
        size="small"
    >
      <easy-card title="停车信息">
        <el-row>
          <el-col :span="12">
            <el-form-item label="车场名称" prop="parkingName" required>
              <el-input
                  v-model="model.parkingName"
                  placeholder="车场名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车场简称" prop="parkingNickName" required>
              <el-input
                  v-model="model.parkingNickName"
                  placeholder="车场简称"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="区划" prop="regionId" required>
              <el-cascader v-model="model.regionId" :clearable="true" :filterable="true" :props="props"
                           :show-all-levels="false" placeholder="请选择区域"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="详细地址" prop="address" required>
              <el-input
                  v-model="model.address"
                  placeholder="详细地址"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="车场经纬度" prop="parkingLocation" required>
              <Location
                  v-model="model.parkingLocation"
                  @makesure="onLocationMakeSure"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="泊位个数" prop="parkingSpaceCount" required>
              <el-input
                  v-model="model.parkingSpaceCount"
                  placeholder="泊位个数"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="停车场类型" prop="parkingType" required>
              <el-select
                  v-model="model.parkingType"
                  placeholder="停车场类型"
                  style="width: 100%"
              >
                <el-option label="路内" value="1"></el-option>
                <el-option label="路外" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车场附加服务" prop="parkingService">
              <el-select v-model="model.parkingService" style="width: 100%">
                <el-option label="车辆保养" value="1"></el-option>
                <el-option label="洗车" value="2"></el-option>
                <el-option label="餐饮娱乐" value="3"></el-option>
                <el-option label="公共厕所" value="4"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="充电服务" prop="chargeType" required>
              <el-select
                  v-model="model.chargeType"
                  placeholder="充电服务"
                  style="width: 100%"
              >
                <el-option label="配有充电桩" value="1"></el-option>
                <el-option label="不配充电桩" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车场管理员" prop="parkingManager">
              <el-input
                  v-model="model.parkingManager"
                  placeholder="车场管理员"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="管理员联系方式" prop="managerMobile">
              <el-input
                  v-model="model.managerMobile"
                  placeholder="管理员联系方式"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="线上可见" prop="isVisibleOnLine" required>
              <el-select
                  v-model="model.isVisibleOnLine"
                  placeholder="是否可见"
                  style="width: 100%"
              >
                <el-option label="是" value="0"></el-option>
                <el-option label="否" value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="OCR调用系统" prop="ocrType">
              <el-select
                  v-model="model.ocrType"
                  placeholder="OCR调用系统"
                  style="width: 100%"
              >
                <el-option label="自主高位OCR" value="1"></el-option>
                <el-option label="自主视频桩OCR" value="2"></el-option>
                <el-option label="阿里OCR" value="3"></el-option>
                <el-option label="无" value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="无牌车扫码入场" prop="unlicensedCarIntoType">
              <el-select
                  v-model="model.unlicensedCarIntoType"
                  placeholder="无牌车扫码入场"
                  style="width: 100%"
              >
                <el-option label="相机校验" value="1"></el-option>
                <el-option label="无需校验" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="客服电话" prop="custorm">
              <el-input
                  v-model="model.custorm"
                  placeholder="客服电话"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="部署模式">
              <el-select
                  v-model="model.deployMode"
                  placeholder="部署模式"
                  style="width: 100%"
              >
                <el-option label="联网版（独立部署）" value="1"></el-option>
                <el-option label="联网版（分布部署）" value="2"></el-option>
                <el-option label="单机版" value="3"></el-option>
                <el-option label="直连云" value="4"></el-option>
                <el-option label="其他" value="5"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="是否可预约" prop="isReserved" required>
              <el-select
                  v-model="model.isReserved"
                  placeholder="是否可预约"
                  style="width: 100%"
                  @change="changeReservedSelect"
              >
                <el-option label="是" value="0"></el-option>
                <el-option label="否" value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="showTimeSelect" :span="12">
            <el-form-item label="可预约时段" prop="timeValue">
              <el-time-picker
                  v-model="model.timeValue"
                  end-placeholder="结束时间"
                  is-range
                  placeholder="选择时间范围"
                  range-separator="至"
                  start-placeholder="开始时间"
                  style="width:100%"
                  value-format="HH:mm:ss"
                  @input="changeTime"
              >
              </el-time-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
                label="车场平面图"
                prop="parkingPlanGraphImages"
                required
            >
              <el-upload
                  ref="graphImageUpload"
                  :file-list="graphFileList"
                  :http-request="doGraphImageUpload"
                  :on-remove="onGraphImageRemove"
                  action=""
                  list-type="picture-card"
              >
                <span>点击上传</span>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
                label="车场实景图"
                prop="parkingRealPictures"
                required
            >
              <el-upload
                  ref="realImageUpload"
                  :file-list="realFileList"
                  :http-request="doRealImageUpload"
                  :on-remove="onRealImageRemove"
                  action=""
                  list-type="picture-card"
              >
                <span>点击上传</span>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </easy-card>
      <easy-card title="企业信息">
        <el-row>
          <el-col :span="12">
            <el-form-item label="结算方名称" prop="settlementId" required>
              <Settlement v-model="model.settlementId"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="父级车场" prop="parentParkingId">
              <SuggestParking
                  v-model="model.parentParkingId"
                  placeholder="请选择父级车场"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </easy-card>
      <el-button class="xxd-button_save" type="primary" @click="save"
      >保存
      </el-button
      >
    </el-form>
  </div>
</template>

<script>
import {getRegionList} from "@/api/user";
import Location from "@/components/Location";
import Settlement from "@/components/Settlement";
import SuggestParking from "@/components/SuggestParking";
import {doUploadImage, getParkingImage,} from "@/api/parking";
// createAndUpdateParkingLotInfo, 
export default {
  name: "add-parking-basic",
  components: {
    Location,
    Settlement,
    SuggestParking,
  },

  props: {
    parkingModelJSON: String,
  },

  data() {
    return {
      defaultRegion: [],
      props: {
        lazy: true,
        async lazyLoad(node, resolve) {
          const {level, value} = node;
          console.log('node.value:', value)
          let regionId = level === 0 ? 0 : value;
          debugger
          getRegionList({regionId: regionId})
              .then(({returnObject}) => {
                debugger
                console.log('getRegionList111:', returnObject.regionResultVoList)
                const nodes = returnObject.regionResultVoList.map(item => ({
                      value: item.id,
                      label: item.name,
                      leaf: item.level == 3,
                    })
                )
                resolve(nodes)
              })
        }
      },
      isLoading: false,
      rules: {
        parkingName: [{required: true, message: "停车场名称不能为空"}],
        parkingNickName: [{required: true, message: "停车场简称不能为空"}],
        address: [{required: true, message: "详细地址不能为空"}],
        parkingLocation: [{required: true, message: "车场经纬度不能为空"}],
        parkingSpaceCount: [{required: true, message: "泊位个数不能为空"}],
        parkingType: [{required: true, message: "请选择停车场类型"}],
        chargeType: [{required: true, message: "请选择充电类型"}],
        isVisibleOnLine: [{required: true, message: "请选择是否可见"}],
        settlementId: [{required: true, message: "请选择结算方"}],
        parkingPlanGraphImages: [
          {required: true, message: "车场平面图不能为空"},
        ],
        parkingRealPictures: [
          {required: true, message: "车场实景图不能为空"},
        ],
        isReserved: [{required: true, message: "请选择是否可预约"}],
      },
      // 平面图列表
      graphFileList: [],
      // 实景图列表
      realFileList: [],
      // 数据模型
      model: {
        parkingId: "",
        parkingName: "",
        parkingNickName: "",
        regionId: "530129", // 先写死
        address: "",
        longitude: "",
        latitude: "",
        parkingLocation: "",
        parkingSpaceCount: "",
        isVisibleOnLine: "0",
        parkingType: "2",
        parkingService: "",
        chargeType: "1",
        parkingManager: "",
        managerMobile: "",
        ocrType: "1",
        unlicensedCarIntoType: "",
        custorm: "",
        deployMode: "",
        parkingPlanGraphImages: [],
        parkingRealPictures: [],
        settlementId: "",
        parentParkingId: "",
        motionType: "",
        isReserved: "0",
        timeValue: [
          '09:00:00',
          '18:00:00'
        ],
        reservationStartDate: "",
        reservationEndDate: "",
      },
      showTimeSelect: true, //显示隐藏可预约时段选项
    };
  },

  watch: {
    parkingModelJSON(newVal) {
      if (newVal !== "") {
        const returnObject = JSON.parse(newVal);

        this.model = {
          parkingId: returnObject.id,
          parkingName: returnObject.name,
          parkingNickName: returnObject.shortName,
          regionId: returnObject.regionId, // 先写死
          address: returnObject.address,
          longitude: returnObject.longitude,
          latitude: returnObject.latitude,
          parkingLocation: returnObject.longitude + "," + returnObject.latitude,
          parkingSpaceCount: returnObject.parkingSpaceNumber,
          isVisibleOnLine: !isNaN(returnObject.isVisibleOnLine)
              ? returnObject.isVisibleOnLine.toString()
              : returnObject.isVisibleOnLine,
          parkingType: !isNaN(returnObject.parkingLotType)
              ? returnObject.parkingLotType.toString()
              : returnObject.parkingLotType,
          parkingService: returnObject.parkingService,
          chargeType: !isNaN(returnObject.ifChargingPile)
              ? returnObject.ifChargingPile.toString()
              : returnObject.ifChargingPile,
          parkingManager: returnObject.linkMan,
          managerMobile: returnObject.linkTel,
          ocrType: returnObject.ocrType,
          unlicensedCarIntoType: returnObject.unlicensedCarIntoType,
          custorm: returnObject.serviceTel,
          deployMode: returnObject.deployMode,
          parkingPlanGraphImages:
              returnObject.planDefaultAttachmentId !== ""
                  ? JSON.parse(returnObject.planDefaultAttachmentId)
                  : [],
          parkingRealPictures:
              returnObject.sceneDefaultAttachmentId !== ""
                  ? JSON.parse(returnObject.sceneDefaultAttachmentId)
                  : [],
          settlementId: returnObject.settlementCompanyId,
          parentParkingId: returnObject.parentId,
          // 0-运营,1-机关事业单位,2-联网
          motionType: 0,
          isReserved: returnObject.isReserved.toString(),
          reservationStartDate: returnObject.reservationStartDate,
          reservationEndDate: returnObject.reservationEndDate,
        };
        // const timeList = [];
        // timeList.push(this.model.reservationStartDate);
        // timeList.push(this.model.reservationEndDate);
        // this.model.timeValue = timeList;
        this.model.timeValue = [this.model.reservationStartDate, this.model.reservationEndDate];
        // 获取平面列表
        this.getAllGraphImages(this.model.parkingPlanGraphImages);
        this.getAllRealImages(this.model.parkingRealPictures);
      }
    },
  },

  methods: {
    // 车场平面图删除
    onGraphImageRemove(file, fileList) {
      this.model.parkingPlanGraphImages = fileList.map((item) => item.id);
    },

    onRealImageRemove(file, fileList) {
      this.model.parkingRealPictures = fileList.map((item) => item.id);
    },

    // 批量获取平面图列表
    getAllGraphImages(attachmentIds) {
      const requestList = attachmentIds.map((item) => {
        return this.getParkingImage(item);
      });

      Promise.all(requestList).then((res) => {
        this.graphFileList = res;
      });
    },
    // 获取车场实景图列表
    getAllRealImages(attachmentIds) {
      const requestList = attachmentIds.map((item) => {
        return this.getParkingImage(item);
      });

      Promise.all(requestList).then((res) => {
        this.realFileList = res;
      });
    },
    async getParkingImage(attachmentId) {
      const res = await getParkingImage(attachmentId);
      if (res && res.code === 30 && res.result) {
        return {
          url: res.returnObject,
          id: attachmentId,
        };
      }
    },
    onLocationMakeSure(point) {
      this.model.parkingLocation = point;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader(); //定义方法读取文件
        reader.readAsDataURL(file); //开始读文件  本身是图片的二进制数据 进行base64加密形成字符串
        reader.onload = () => resolve(reader.result); //成功返回对应的值 reader.result可以直接放在img标签中使用
        reader.onerror = (error) => reject(error); //失败返回失败信息
      });
    },
    // 上传车场平面图
    async doGraphImageUpload(file) {
      const isLimit = file.file.size / 1024 < 1000;
      if (!isLimit) {
        this.$message.warning("图片大小不能超过1M! 请重新上传");
        // this.$refs.graphImageUpload.clearFiles();
        return;
      }
      const base64 = await this.getBase64(file.file);
      const res = await doUploadImage(base64);
      if (res && res.code === 30 && res.result) {
        this.model.parkingPlanGraphImages.push(res.returnObject.id);
      }
    },
    // 上传实景平面图
    async doRealImageUpload(file) {
      const isLimit = file.file.size / 1024 < 1000;
      if (!isLimit) {
        this.$message.warning("图片大小不能超过1M! 请重新上传");
        // this.$refs.realImageUpload.clearFiles();
        return;
      }
      const base64 = await this.getBase64(file.file);
      const res = await doUploadImage(base64);
      if (res && res.code === 30 && res.result) {
        this.model.parkingRealPictures.push(res.returnObject.id);
      }
    },

    async save() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const location = this.model.parkingLocation.split(",");
          this.model.longitude = location[0];
          this.model.latitude = location[1];
          this.model.motionType = this.$route.query.motionType;
          if (this.model.timeValue.length > 0) {
            this.model.reservationStartDate = this.model.timeValue[0];
            this.model.reservationEndDate = this.model.timeValue[1];
          }
          this.isLoading = true;
          // const res = await createAndUpdateParkingLotInfo(this.model);
          // this.isLoading = false;
          // if (res && res.code === 30 && res.result) {
          //   this.$message({
          //     message: "保存车场基本信息成功!",
          //     type: "success",
          //   });
          //   this.$emit("success", res.returnObject);
          // }
        }
      });
    },
    //根据选择判断是否隐藏可预约时段选择
    changeReservedSelect() {
      if (this.model.isReserved === "0") {
        this.showTimeSelect = true;
      } else {
        this.showTimeSelect = false;
        this.model.timeValue = [
          "09:00:00",
          "18:00:00",
        ];
      }
    },

    //强制刷新时间选择器
    changeTime() {
      this.$forceUpdate()
    }
  },
};
</script>

<style lang="less" scoped>
// .add-parking-basic {
// }
</style>
