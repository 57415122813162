<template>
  <div v-loading="isLoading" class="page">
    <div class="tabs-wrap">
      <el-tabs
          v-model="currentTab"
          :before-leave="onBeforeLeave"
          type="border-card"
      >
        <el-tab-pane label="基础信息" name="basic">
          <AddParkingBaisc
              :parkingModelJSON="parkingModelJSON"
              @success="onSuccess"
          />
        </el-tab-pane>
        <el-tab-pane label="分润规则" name="share">
          <ShareRules
              :parkingId="parkingId"
              :parkingName="parkingName"
          />
        </el-tab-pane>
        <el-tab-pane label="运营规则" name="operation">
          <Operation
              :parkingId="parkingId"
              :parkingModelJSON="parkingModelJSON"
          />
        </el-tab-pane>
        <el-tab-pane label="计费规则" name="price">
          <Price :parkId="parkingId"/>
        </el-tab-pane>
        <el-tab-pane label="扩展信息" name="extension">
          <Extension
              :parkingId="parkingId"
              :parkingModelJSON="parkingModelJSON"
              @success="onExtensionSuccess"
          />
        </el-tab-pane>
        <!-- <el-tab-pane label="通道配置" name="passage">
          <Passage :parkingId="parkingId" />
        </el-tab-pane> -->
      </el-tabs>
      <el-button class="back-button" type="text" @click="goBack"
      ><i class="el-icon-back"></i>上一页
      </el-button
      >
    </div>
  </div>
</template>

<script>
import AddParkingBaisc from "@/pages/share/parking/components/basic.vue";
import ShareRules from "@/pages/parking/components/share.vue";
import Operation from "@/pages/parking/components/operation.vue";
import Extension from "@/pages/parking/components/extension.vue";
import Price from "./components/price.vue";
import {getParkingDetail} from "@/api/parking";

export default {
  name: "share-add-parking",
  components: {
    AddParkingBaisc,
    ShareRules,
    Operation,
    Extension,
    Price,
  },
  data() {
    return {
      parkingId: "",
      parkingName: "",
      currentTab: "basic",
      type: "add",
      parkingModelJSON: "",
    };
  },
  methods: {
    //返回上一页
    goBack() {
      this.$router.back();
    },
    onBeforeLeave() {
      if (!this.parkingId) {
        this.$message({
          message: "请先完成车场基本信息",
          type: "warning",
        });
        return false;
      }
      return true;
    },
    async getParkingDetail(parkingId) {
      this.isLoading = true;
      const res = await getParkingDetail(parkingId);
      this.isLoading = false;

      if (res && res.code === 30 && res.result) {
        const returnObject = res.returnObject;
        this.parkingName = returnObject.name;
        this.parkingModelJSON = JSON.stringify(returnObject);
      }
    },
    onSuccess(e) {
      this.parkingId = e.id;
      this.parkingName = e.name;
    },
    onExtensionSuccess() {
      this.getParkingDetail(this.parkingId);
    },
  },
  mounted() {
    const parkingId = this.$route.query.parkingId;
    if (parkingId) {
      this.parkingId = parkingId;
      this.getParkingDetail(this.parkingId);
    }
  },
};
</script>

<style lang="less" scoped>
.tabs-wrap {
  position: relative;

  .back-button {
    position: absolute;
    right: 20px;
    top: 0;
    font-size: 15px;
    font-weight: normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 18px;

    i {
      font-size: 18px;
    }
  }
}
</style>
